import PropTypes from 'prop-types';

import resolve from 'core/resolver/resolve';

import { getOffset } from 'core/utils/url-helper';
import { filterRequiredParams } from 'core/utils/api';
import modelPropTypes, {
  topicAttributes,
} from 'core/utils/prop-types/model';

import { Block, Section } from 'core/components/Grid';
import H1 from 'core/components/H1';
import ListPage from 'core/components/ListPage';
import withPageHocs from 'core/components/withPageHocs';

import RubricTopics from 'site/components/RubricTopics';
import LightPageWrapper from 'site/components/LightPageWrapper';
import InnerSideColumn from 'site/components/InnerSideColumn';
import Boroda from 'site/components/Boroda';

import Card1 from 'site/cards/Card1';
import Card2 from 'site/cards/Card2';
import Card6 from 'site/cards/Card6';

import { RUBRICS_PAGE_LIMIT as LIMIT } from 'site/constants';

const fields = filterRequiredParams([Card1, Card2, Card6], 'fields');

function NewsPage(props) {
  const {
    topics,
    meta,
  } = props;

  return (
    <>
      <LightPageWrapper>
        <Section>
          <Block>
            <H1>Новости</H1>
            <ListPage
              title={`Новости науки и техники ${new Date().getFullYear()}`}
              description='Новости на Indicator.ru - последние новости об открытиях российских и зарубежных ученых, острые дискуссии об организации науки в России и взаимодействии науки и бизнеса, собственные рейтинги российских ученых, научных организаций и инновационных компаний.'
              rawData={topics}
              meta={meta}
              limit={LIMIT}
            >
              {({ content }) => {
                return (
                  <RubricTopics topics={content} />
                );
              }}
            </ListPage>
          </Block>
          <InnerSideColumn />
        </Section>
      </LightPageWrapper>
      <Boroda />
    </>
  );
}

NewsPage.propTypes = {
  topics: PropTypes.arrayOf(modelPropTypes(topicAttributes).isRequired),
  meta: PropTypes.shape({
    filtered_count: PropTypes.number,
  }),
};

const dataProvider = resolve({
  topics(props) {
    const {
      bebopApi,
      topics,
      location: { search },
    } = props;

    return topics || bebopApi
      .getTopics({
        limit: LIMIT,
        include: 'image',
        offset: getOffset(search, LIMIT),
        fields,
        topic_type: 'news',
        with_filtered_count: 1,
      })
      .catch(e => console.error(e));
  },
});

export default withPageHocs(dataProvider)(NewsPage);

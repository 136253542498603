import PropTypes from 'prop-types';
import withBreakpoint from 'core/components/breakpoint/withBreakpoint';
import Indent from './Indent';

import { SIDE_INDENT_MOBILE } from 'site/constants';

function NegativeMobile(props) {
  const {
    children,
    isMobile,
  } = props;

  const indent = isMobile ? -SIDE_INDENT_MOBILE : undefined;

  return (
    <Indent left={indent} right={indent}>
      {children}
    </Indent>
  );
}

NegativeMobile.propTypes = {
  isMobile: PropTypes.bool,
};

export default withBreakpoint(NegativeMobile);

import resolve from 'core/resolver/resolve';

import { denormalizeData, filterRequiredParams } from 'core/utils/api';

import Card1 from 'site/cards/Card1';
import Card2 from 'site/cards/Card2';
import Card3 from 'site/cards/Card3';
import Card4 from 'site/cards/Card4';
import Card5 from 'site/cards/Card5';
import CardEventMain from 'site/cards/CardEventMain';

import { dailyPhotoFetcher } from 'site/fetchers/dailyPhotoFetcher';

import { RUBRICS } from 'site/constants';


const card1Props = {
  fields: filterRequiredParams([Card1], 'fields'),
  include: filterRequiredParams([Card1], 'include'),
};

export default resolve({
  main: ({ bebopApi, consoleError }) => {
    return bebopApi
      .getTopics({
        list: 'main',
        sort: 'list',
        limit: 3,
        fields: filterRequiredParams([Card2, Card3], 'fields'),
        include: filterRequiredParams([Card2, Card3], 'include'),
      })
      .then(denormalizeData)
      .catch(consoleError('main topics', []));
  },

  news: ({ bebopApi, consoleError }) => {
    return bebopApi
      .getTopics({
        topic_type: 'news',
        limit: 6,
        ...card1Props,
      })
      .then(denormalizeData)
      .catch(consoleError('news topics', []));
  },

  discussion: ({ bebopApi, consoleError }) => bebopApi
    .getTopics({
      limit: 5,
      tag: 'debate-club',
      include: 'image,rubric',
      fields: filterRequiredParams([Card2, Card5, Card1], 'fields'),
    })
    .then(denormalizeData)
    .catch(() => consoleError('discussion block', [])),

  events: ({ bebopApi, consoleError }) => bebopApi
    .getTopics({
      list: 'events',
      sort: 'list',
      limit: 5,
      fields: filterRequiredParams([CardEventMain], 'fields'),
      include: filterRequiredParams([CardEventMain], 'include'),
    })
    .then(denormalizeData)
    .catch(() => consoleError('events block', [])),

  popular: ({ bebopApi, consoleError }) => bebopApi
    .getTopics({
      limit: 5,
      sort: '-views_count',
      ...card1Props,
    })
    .then(denormalizeData)
    .catch(() => consoleError('popular block', [])),

  rubrics: ({ bebopApi, consoleError }) => {
    const payloads = RUBRICS.map(rubric => {
      return bebopApi
        .getTopics({
          limit: 4,
          include: filterRequiredParams([Card1, Card2, Card4], 'include'),
          fields: filterRequiredParams([Card1, Card2, Card4], 'fields'),
          topic_type: '-event',
          rubric: rubric.slug,
        })
        .then(denormalizeData)
        .then(data => ({ ...rubric, topics: data }))
        .catch(consoleError(`${rubric.title} rubric error`, { ...rubric, topics: [] }));
    });

    return Promise.allSettled(payloads)
      .then(results => results.map(item => item.value))
      .catch(consoleError('rubrics block',
        RUBRICS.map(item => ({
          ...item,
          topics: [],
        }))
      ));
  },

  dailyPhoto: dailyPhotoFetcher(),
});

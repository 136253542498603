import PropTypes from 'prop-types';

import topicProvider from 'core/resolver/data/topic';

import withPageHocs from 'core/components/withPageHocs';
import Topic from 'core/components/Topic';
import { Block, Section } from 'core/components/Grid';
import Link from 'core/components/Link';
import Button from 'core/components/Button';
import Comments from 'core/components/Comments';

import InnerSideColumn from 'site/components/InnerSideColumn';
import Boroda from 'site/components/Boroda';
import LightPageWrapper from 'site/components/LightPageWrapper';

import TopicHeader from 'site/pages/topic/TopicHeader';


function CommentsPage({ content }) {
  return (
    <>
      <LightPageWrapper>
        <Topic content={content}>
          {topic => {
            const {
              id,
              attributes: {
                link,
                is_comments_disabled: isCommentsDisabled,
              },
            } = topic;

            return (
              <Section>
                <Block>
                  <TopicHeader
                    content={topic}
                    showComments={false}
                    showMedia={false}
                    showAnnounce={false}
                  />
                  <Link to={link} >
                    <Button size='small'>Вернуться к статье</Button>
                  </Link>
                  <Comments xid={id} isCommentsDisabled={isCommentsDisabled} />
                </Block>
                <InnerSideColumn />
              </Section>
            );
          }}
        </Topic>
      </LightPageWrapper>
      <Boroda />
    </>
  );
}

CommentsPage.propTypes = {
  content: PropTypes.object,
};

export default withPageHocs(topicProvider)(CommentsPage);

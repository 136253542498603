import PropTypes from 'prop-types';

import { denormalizeData } from 'core/utils/api';

import resolve from 'core/resolver/resolve';

import withPageHocs from 'core/components/withPageHocs';
import { Block, Section } from 'core/components/Grid';
import H1 from 'core/components/H1';
import Page from 'core/components/Page';

import LightPageWrapper from 'site/components/LightPageWrapper';
import InnerSideColumn from 'site/components/InnerSideColumn';
import Boroda from 'site/components/Boroda';

import TagsContent from './TagsContent';

function TagsPage({ tags }) {
  return (
    <Page
      title='Цифровая энциклопедия'
      description='Цифровая энциклопедия на Indicator.ru - последние новости об открытиях российских и зарубежных ученых, острые дискуссии об организации науки в России и взаимодействии науки и бизнеса, собственные рейтинги российских ученых, научных организаций и инновационных компаний.'
    >
      <LightPageWrapper>
        <Section>
          <Block>
            <H1>Цифровая энциклопедия</H1>
            <TagsContent tags={tags} />
          </Block>
          <InnerSideColumn />
        </Section>
      </LightPageWrapper>
      <Boroda />
    </Page>
  );
}

TagsPage.propTypes = {
  tags: PropTypes.array,
};

const LIMIT = 100;

/**
 * Обходим ограничение апишки в 100 тегов на запрос
 */
const dataProvider = resolve({
  tags: async ({ tags, bebopApi, consoleError }) => {
    try {
      if (tags) return tags;

      const firstTagsPromise = bebopApi
        .getTags({
          with_filtered_count: 1,
          sort: 'title',
          limit: LIMIT,
        })
        .catch(consoleError('tags count', {}));

      const rawFirstTags = await firstTagsPromise;
      const firstTags = denormalizeData(rawFirstTags);
      const count = rawFirstTags.value?.meta?.filtered_count || 0;

      if (count <= LIMIT) return firstTags;

      const iterations = Math.ceil(count / LIMIT);
      const payloads = [];

      for (let i = 1; i < iterations; i++) {
        payloads.push(
          bebopApi
            .getTags({
              offset: i * LIMIT,
              sort: 'title',
              limit: LIMIT,
            })
        );
      }

      return Promise.all(payloads)
        .then(data => {
          return data.reduce((stack, item) => {
            const denormalizedItem = denormalizeData(item);

            return stack.concat(denormalizedItem);
          }, firstTags);
        });
    } catch (e) {
      consoleError(e);
      return [];
    }
  },
});

export default withPageHocs(dataProvider)(TagsPage);

import PropTypes from 'prop-types';

import { filterRequiredParams } from 'core/utils/api';

import resolve from 'core/resolver/resolve';

import { rawRubricFetcher, rawTopicsFetcher } from 'core/fetchers/rubric';

import withPageHocs from 'core/components/withPageHocs';
import Rubric from 'core/components/Rubric';
import { Block, Section } from 'core/components/Grid';

import LightPageWrapper from 'site/components/LightPageWrapper';
import InnerSideColumn from 'site/components/InnerSideColumn';
import RubricTopics from 'site/components/RubricTopics';
import Boroda from 'site/components/Boroda';

import Card1 from 'site/cards/Card1';
import Card2 from 'site/cards/Card2';
import Card6 from 'site/cards/Card6';

import { dailyPhotoFetcher } from 'site/fetchers/dailyPhotoFetcher';

import { RUBRICS_PAGE_LIMIT } from 'site/constants';


function RubricPage({ rawRubric, rawTopics, dailyPhoto }) {
  return (
    <>
      <LightPageWrapper>
        <Section>
          <Block>
            <Rubric
              rawRubric={rawRubric}
              rawTopics={rawTopics}
              limit={RUBRICS_PAGE_LIMIT}
            >
              {({ topics, rawRubric: rubric }) => (
                <RubricTopics
                  rubric={rubric.data.attributes.slug}
                  topics={topics}
                  dailyPhoto={dailyPhoto}
                />
              )}
            </Rubric>
          </Block>
          <InnerSideColumn />
        </Section>
      </LightPageWrapper>
      <Boroda />
    </>
  );
}

RubricPage.propTypes = {
  rawRubric: PropTypes.object,
  rawTopics: PropTypes.object,
  dailyPhoto: PropTypes.array,
};

const dataProvider = resolve({
  rawRubric: rawRubricFetcher(),
  rawTopics: rawTopicsFetcher({
    limit: RUBRICS_PAGE_LIMIT,
    include: 'image',
    topic_type: '-daily_photo,event',
    fields: filterRequiredParams([Card1, Card2, Card6], 'fields'),
  }),
  dailyPhoto: dailyPhotoFetcher(),
});

export default withPageHocs(dataProvider)(RubricPage);

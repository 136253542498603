import { denormalizeData, filterRequiredParams } from 'core/utils/api';

import CardPhotoMain from 'site/cards/CardPhotoMain';


export const dailyPhotoFetcher = (customParams) => (componentProps) => {
  const props = { ...componentProps, ...customParams };

  const {
    bebopApi,
    consoleError,
  } = props;

  return bebopApi
    .getTopics({
      limit: 1,
      topic_type: 'daily_photo',
      fields: filterRequiredParams([CardPhotoMain], 'fields'),
      include: filterRequiredParams([CardPhotoMain], 'include'),
    })
    .then(denormalizeData)
    .catch(consoleError('daily photo block', []));
};

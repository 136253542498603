import { Fragment } from 'react';
import queryString from 'query-string';
import PropTypes from 'prop-types';

import resolve from 'core/resolver/resolve';

import { Redirect } from 'core/libs/router';

import { getOffset } from 'core/utils/url-helper';
import { filterRequiredParams } from 'core/utils/api';
import breakpointPropTypes from 'core/utils/prop-types/breakpoint';

import compose from 'recompose/compose';
import withProps from 'recompose/withProps';

import { Block, Section } from 'core/components/Grid';
import { withBreakpoint, Mobile } from 'core/components/breakpoint';
import H1 from 'core/components/H1';
import withPageHocs from 'core/components/withPageHocs';

import LightPageWrapper from 'site/components/LightPageWrapper';
import InnerSideColumn from 'site/components/InnerSideColumn';
import { Indent } from 'site/components/Wrappers';
import Boroda from 'site/components/Boroda';
import Select, { Option } from 'site/components/Select';

import CardEventList from 'site/cards/CardEventList';

import { EVENTS_PAGE_LIMIT } from 'site/constants';

import EventsTopics from './EventsTopics';

import cities from './cities';

import styles from './index.styl';


const fields = filterRequiredParams([CardEventList], 'fields');

function onCityChange(history, event) {
  event.preventDefault();
  history.push(`/events?${queryString.stringify({
    city_slug: event.target.value,
  })}`);
}

function EventsPage(props) {
  const {
    topics,
    citySlug,
    history,
    breakpoint,
    isDesktop,
  } = props;

  if (citySlug === 'all') {
    return <Redirect to='/events' />;
  }

  return (
    <Fragment>
      <LightPageWrapper>
        <Section>
          <Block>
            <div className={styles.header + ' ' + styles[breakpoint]}>
              <H1>События</H1>
              <Mobile><Indent top={10} /></Mobile>
              <Select
                defaultValue={citySlug ? citySlug : 'all'}
                onChange={onCityChange.bind(this, history)}
              >
                <Option value='all'>Все</Option>
                {cities.map(city => (
                  <Option
                    key={city.slug}
                    value={city.slug}
                  >
                    {city.title}
                  </Option>
                ))}
              </Select>
            </div>
            <EventsTopics topics={topics} isDesktop={isDesktop} />
          </Block>
          <InnerSideColumn />
        </Section>
      </LightPageWrapper>
      <Boroda />
    </Fragment>
  );
}

EventsPage.propTypes = {
  topics: PropTypes.object,
  citySlug: PropTypes.string,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  breakpoint: breakpointPropTypes(),
  isDesktop: PropTypes.bool,
};

const dataProvider = resolve({
  topics(props) {
    const {
      bebopApi,
      topics,
      location: { search },
      citySlug,
    } = props;

    return topics || bebopApi
      .getTopics({
        limit: EVENTS_PAGE_LIMIT,
        include: 'image,rubric,event',
        fields,
        sort: '-event.started_at',
        offset: getOffset(search, EVENTS_PAGE_LIMIT),
        topic_type: 'event',
        with_filtered_count: 1,
        plugin: JSON.stringify({
          event: {
            city_slug: citySlug,
          },
        }),
      })
      .catch(e => console.error(e));
  },
});

export default compose(
  withProps(({ location }) => ({
    citySlug: queryString.parse(location.search).city_slug,
  })),
  withPageHocs(dataProvider),
  withBreakpoint,
)(EventsPage);

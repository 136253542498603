import PropTypes from 'prop-types';

import { rawAuthorsFetcher } from 'core/fetchers/authorsPage';

import resolve from 'core/resolver/resolve';

import withPageHocs from 'core/components/withPageHocs';
import H1 from 'core/components/H1';
import { Block, Section } from 'core/components/Grid';
import AuthorsPage from 'core/components/AuthorsPage';

import LightPageWrapper from 'site/components/LightPageWrapper';
import InnerSideColumn from 'site/components/InnerSideColumn';
import Boroda from 'site/components/Boroda';
import { Indent } from 'site/components/Wrappers';


function Authors({ rawAuthors }) {
  return (
    <>
      <LightPageWrapper>
        <Section>
          <Block>
            <H1>Авторы</H1>
            <Indent top={20} />
            <AuthorsPage rawAuthors={rawAuthors} />
          </Block>
          <InnerSideColumn />
        </Section>
      </LightPageWrapper>
      <Boroda />
    </>
  );
}

Authors.propTypes = {
  rawAuthors: PropTypes.object,
};

const dataProvider = resolve({
  rawAuthors: rawAuthorsFetcher(),
});

export default withPageHocs(dataProvider)(Authors);

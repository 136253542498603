import { Fragment } from 'react';
import PropTypes from 'prop-types';

import replaceCardWithAd from 'core/components/Ad/hoc/replaceCardWithAd';
import { withBreakpoint, Desktop, Mobile } from 'core/components/breakpoint';
import AdWrapper from 'core/components/Ad/AdWrapper';
import Feed from 'core/components/Feed';

import { Indent } from 'site/components/Wrappers';

import CardPhotoMain from 'site/cards/CardPhotoMain';

import { SIDE_INDENT } from 'site/constants';

import RubricsBlock from './RubricsBlock';

import {
  SuperFooter,
  Context,
  Native1 as Native1Rubric,
  Native2 as Native2Rubric,
} from 'site/components/Ads/desktop';

import {
  Listing1,
  ListingSpec,
  Listing2,
  Listing3,
} from 'site/components/Ads/mobile';

function RubricTopics(props) {
  const {
    topics,
    isDesktop,
    dailyPhoto,
  } = props;

  const first = topics.slice(0, 6);
  const second = topics.slice(6, 12);
  const third = topics.slice(12, 18);
  const fourth = topics.slice(18, 24);
  const fifth = topics.slice(24, topics.length);

  const spacing = isDesktop ? 20 : 10;

  return (
    <Fragment>
      {first.length > 0 && (
        <Fragment>
          <Indent top={spacing} />
          <RubricsBlock
            topics={first}
            feedCardsReplacement={{
              0: replaceCardWithAd(Native1Rubric, isDesktop),
            }}
          />
          <AdWrapper top={spacing}>
            <Desktop><SuperFooter /></Desktop>
            <Mobile><Listing1 /></Mobile>
          </AdWrapper>
        </Fragment>
      )}
      {second.length > 0 && (
        <Fragment>
          <Indent top={spacing} />
          <RubricsBlock topics={second} />
          <AdWrapper top={20}>
            <Mobile>
              <ListingSpec />
            </Mobile>
          </AdWrapper>
        </Fragment>
      )}
      {dailyPhoto?.length > 0 && (
        <Indent
          top={spacing}
          left={-(isDesktop && SIDE_INDENT)}
        >
          <Feed
            content={dailyPhoto}
            card={CardPhotoMain}
            interitemSpacing={0}
          />
        </Indent>
      )}
      {third.length > 0 && (
        <Fragment>
          <Indent top={spacing} />
          <RubricsBlock
            topics={third}
            feedCardsReplacement={{
              1: replaceCardWithAd(Native2Rubric, isDesktop),
            }}
          />
          <AdWrapper top={spacing}>
            <Mobile><Listing2 /></Mobile>
          </AdWrapper>
        </Fragment>
      )}
      {fourth.length > 0 && (
        <Fragment>
          <Indent top={spacing} />
          <RubricsBlock topics={fourth} />
          <AdWrapper top={spacing}>
            <Desktop><Context /></Desktop>
            <Mobile><Listing3 /></Mobile>
          </AdWrapper>
        </Fragment>
      )}
      {fifth.length > 0 && (
        <Fragment>
          <Indent top={spacing} />
          <RubricsBlock topics={fifth} />
        </Fragment>
      )}
      <Indent top={spacing} />
    </Fragment>
  );
}

RubricTopics.propTypes = {
  topics: PropTypes.array,
  isDesktop: PropTypes.bool,
  dailyPhoto: PropTypes.array,
};

export default withBreakpoint(RubricTopics);

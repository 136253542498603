import { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import compose from 'recompose/compose';

import { denormalizeData, filterRequiredParams } from 'core/utils/api';

import Feed from 'core/components/Feed';
import Drum from 'core/components/Drum';
import { Block, Section } from 'core/components/Grid';
import Bouesque from 'core/components/Bouesque';
import SideColumn from 'core/components/SideColumn';
import AdWrapper from 'core/components/Ad/AdWrapper';
import { withBreakpoint } from 'core/components/breakpoint';
import { SimpleLoader } from 'core/components/Loader';

import { PageIndent } from 'site/components/Wrappers';
import ColorLine from 'site/components/ColorLine';
import Card2 from 'site/cards/Card2';
import FooterAd from 'site/components/Ads/FooterAd';
import { Ad240x400 } from 'site/components/Ads/desktop';

import { VERTICAL_INDENT } from 'site/constants';

function Boroda({ showAd, isDesktop, hideMostDiscussed }, { bebopApi }) {
  const [content, setContent] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let ignore = false;

    const exitEffect = () => {
      ignore = true;
    };

    if (hideMostDiscussed) return exitEffect;

    setLoading(true);

    bebopApi
      .getTopics({
        limit: 6,
        sort: '-comments_count',
        fields: filterRequiredParams([Card2], 'fields'),
        include: filterRequiredParams([Card2], 'include'),
      })
      .then(data => {
        if (!ignore) {
          setContent(denormalizeData(data));
          setLoading(false);
        }
      })
      .catch(e => {
        if (!ignore) {
          console.error(e);
          setLoading(false);
        }
      });
    return exitEffect;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hideMostDiscussed]);

  if (loading) return <SimpleLoader />;

  const Presenter = isDesktop ? Feed : Drum;
  return (
    <Fragment>
      <PageIndent>
        <Section>
          {content.length > 0 && (
            <Block>
              <Presenter
                content={content}
                title='Обсуждаемое'
                headerComponent={ColorLine}
                card={Card2}
                interitemSpacing={35}
                columns={3}
                grid
                itemWidthMobile={180}
              />
            </Block>
          )}
          <SideColumn>
            {showAd && (
              <AdWrapper top={VERTICAL_INDENT}>
                <Bouesque />
                <Ad240x400 />
              </AdWrapper>
            )}
          </SideColumn>
        </Section>
      </PageIndent>
      <FooterAd />
    </Fragment>
  );
}

Boroda.propTypes = {
  isDesktop: PropTypes.bool,
  showAd: PropTypes.bool,
  hideMostDiscussed: PropTypes.bool,
};

Boroda.contextTypes = {
  bebopApi: PropTypes.object,
};

export default compose(
  withBreakpoint,
)(Boroda);

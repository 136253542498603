import PropTypes from 'prop-types';

import replaceCardWithAd from 'core/components/Ad/hoc/replaceCardWithAd';
import withBreakpoint from 'core/components/breakpoint/withBreakpoint';

import Mjolnir from 'core/components/Mjolnir';

import ColorLine from 'site/components/ColorLine';

import { Native2 } from 'site/components/Ads/desktop';

import Card1 from 'site/cards/Card1';
/**
 * TODO: Card2Type3 имеет больший размер картинки чем тут требуется
 * Сделать новый тип с меньшим размером картинки
 */
import Card2, { Card2Type3 } from 'site/cards/Card2';
import Card5 from 'site/cards/Card5';


function DiscussionClub({ content, isDesktop }) {
  return (
    <Mjolnir
      content={content}
      title='Дискуссионный клуб'
      headerComponent={ColorLine}
      heroCard={isDesktop ? Card5 : Card2Type3}
      card={isDesktop ? Card2 : Card1}
      interitemSpacing={isDesktop ? 40 : 20}
      cardsReplacement={{
        1: replaceCardWithAd(Native2, isDesktop),
      }}
    />
  );
}

DiscussionClub.propTypes = {
  content: PropTypes.array,
  isDesktop: PropTypes.bool,
};

export default withBreakpoint(DiscussionClub);

import color from 'color';
import cx from 'classnames';
import coreTableMarkdown from 'core/components/TopicContent/blocks/tableMarkdown';
import styles from './index.styl';

export default function tableMarkdown(block, parentProps) {
  const { theme } = parentProps;
  return (
    <div className={cx('table', styles.table)}>
      <style jsx>{`
        .table
          &:after
            background-image linear-gradient(to right,\
              ${color(theme.colors.layout).alpha(0).string()} 0%,\
              ${theme.colors.layout} 100%\
            )
      `}</style>
      {coreTableMarkdown(block, parentProps)}
    </div>
  );
}

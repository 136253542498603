import PropTypes from 'prop-types';

import Feed from 'core/components/Feed';

import ColorLine from 'site/components/ColorLine';

import Card1 from 'site/cards/Card1';


export default function MainPopular({ content }) {
  return (
    <Feed
      content={content}
      title='Читайте также'
      headerComponent={ColorLine}
      card={Card1}
      interitemSpacing={20}
    />
  );
}

MainPopular.propTypes = {
  content: PropTypes.array,
};

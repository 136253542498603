import PropTypes from 'prop-types';

import React from 'react';
import Link from 'core/components/Link';
import Mjolnir from 'core/components/Mjolnir';
import H2 from 'core/components/H2';
import { Mobile, Desktop } from 'core/components/breakpoint';
import { Block, Section } from 'core/components/Grid';
import ContentBackground from 'core/components/ContentBackground';
import Scroller from 'core/components/Scroller';
import Button from 'core/components/Button';

import { Indent, PageIndent } from 'site/components/Wrappers';

import { Card1Type2 } from 'site/cards/Card1';
import Card4 from 'site/cards/Card4';
import { Card2Type4, Card2Type3 } from 'site/cards/Card2';

import styles from './index.styl';


export default class MainRubrics extends React.Component {
  static propTypes = {
    content: PropTypes.array,
  };

  state = {
    rubric: this.props.content[0].slug,
  };

  setRubric = rubric => () => {
    this.setState({ rubric });
  };

  render() {
    const { content } = this.props;
    return (
      <ContentBackground>
        <Desktop>
          <Section>
            {content.map(({ title, slug, topics }) => {
              if (!topics?.length) return null;

              return (
                <Block
                  key={slug}
                  desktop={6}
                  mobile={12}
                  className={styles.rubric}
                >
                  <i
                    className={styles.rubric__bg}
                    style={{ backgroundImage: `url("/static/images/rubrics/${slug}_rubric_back.jpg")` }}
                  />
                  <div className={styles.rubric__content}>
                    <H2 className={styles.rubric__title}>
                      <Link to={`/${slug}`} className={styles.rubric__title}>
                        {title}
                      </Link>
                    </H2>
                    <Indent bottom={20} />
                    <Mjolnir
                      content={topics}
                      heroCard={Card4}
                      card={Card2Type4}
                      interitemSpacing={20}
                    />
                  </div>
                </Block>
              );
            })}
          </Section>
        </Desktop>
        <Mobile>
          <div style={{ padding: '20px 0' }}>
            <Scroller>
              {content.map(({ title, slug, topics }) => {
                if (!topics?.length) return null;

                return (
                  <span key={slug} className={styles.rubric}>
                    <Button
                      size='small'
                      type='secondary'
                      onClick={this.setRubric(slug)}
                    >
                      {title}
                    </Button>
                  </span>
                );
              })}
            </Scroller>
            <PageIndent>
              <Mjolnir
                content={content.find(item => item.slug === this.state.rubric).topics}
                heroCard={Card2Type3}
                card={Card1Type2}
                interitemSpacing={20}
              />
            </PageIndent>
          </div>
        </Mobile>
      </ContentBackground>
    );
  }
}

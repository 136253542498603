import PropTypes from 'prop-types';

import {
  tagFetcher,
  topicsFetcher,
} from 'core/fetchers/tagTopics';

import resolve from 'core/resolver/resolve';

import { filterRequiredParams } from 'core/utils/api';

import withPageHocs from 'core/components/withPageHocs';
import TagTopics from 'core/components/TagTopics';
import H1 from 'core/components/H1';
import SocialShare from 'site/components/SocialShare';
import { Block, Section } from 'core/components/Grid';

import LightPageWrapper from 'site/components/LightPageWrapper';
import InnerSideColumn from 'site/components/InnerSideColumn';
import RubricTopics from 'site/components/RubricTopics';
import Boroda from 'site/components/Boroda';
import TopicHeaderImage from 'site/pages/topic/TopicHeaderImage';
import { Indent } from 'site/components/Wrappers';

import Card1 from 'site/cards/Card1';
import Card2 from 'site/cards/Card2';
import Card6 from 'site/cards/Card6';

const LIMIT = 12;


function TagPage({ tag: rawTag, topics }) {
  return (
    <>
      <LightPageWrapper>
        <Section>
          <Block>
            <TagTopics
              tag={rawTag}
              topics={topics}
              limit={LIMIT}
              titleComponent={({ children, tag }) => (
                <>
                  <H1>#{children}</H1>
                  <SocialShare />
                  {tag?.relationships?.image?.data && (
                    <>
                      <Indent top={25} />
                      <TopicHeaderImage content={tag} />
                    </>
                  )}
                </>
              )}
              headerSpacing={25}
            >
              {RubricTopics}
            </TagTopics>
          </Block>
          <InnerSideColumn />
        </Section>
      </LightPageWrapper>
      <Boroda />
    </>
  );
}

TagPage.propTypes = {
  tag: PropTypes.object,
  topics: PropTypes.object,
};

const dataProvider = resolve({
  tag: tagFetcher(),
  topics: topicsFetcher({
    limit: LIMIT,
    include: 'image',
    fields: filterRequiredParams([Card1, Card2, Card6], 'fields'),
  }),
});

export default withPageHocs(dataProvider)(TagPage);

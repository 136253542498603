import { Fragment } from 'react';
import PropTypes from 'prop-types';

import { withBreakpoint, Desktop } from 'core/components/breakpoint';

import Feed from 'core/components/Feed';
import Raker from 'core/components/Raker';

import { Indent } from 'site/components/Wrappers';

import Card1 from 'site/cards/Card1';
import Card2, { Card2Type3 } from 'site/cards/Card2';
import Card6 from 'site/cards/Card6';


function RubricsBlock(props) {
  const {
    topics,
    isDesktop,
    feedCardsReplacement,
  } = props;

  const first = topics.slice(0, 3);
  const second = topics.slice(3, topics.length);

  const spacing = isDesktop ? 35 : 20;

  const commonProps = {
    interitemSpacing: spacing,
    card: isDesktop ? Card2 : Card1,
  };

  return (
    <Fragment>
      {first.length > 0 && (
        <Raker
          {...commonProps}
          heroCard={isDesktop ? Card6 : Card2Type3}
          content={first}
          sidebarGridSize={4}
        />
      )}
      {second.length > 0 && (
        <Fragment>
          <Desktop>
            <Indent top={spacing} />
          </Desktop>
          <Feed
            {...commonProps}
            columns={3}
            grid
            content={second}
            cardsReplacement={feedCardsReplacement}
          />
        </Fragment>
      )}
    </Fragment>
  );
}

RubricsBlock.propTypes = {
  topics: PropTypes.array,
  isDesktop: PropTypes.bool,
  feedCardsReplacement: PropTypes.object,
};

export default withBreakpoint(RubricsBlock);

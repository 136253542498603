import PropTypes from 'prop-types';

import resolve from 'core/resolver/resolve';

import { filterRequiredParams } from 'core/utils/api';

import {
  authorFetcher,
  topicsFetcher,
} from 'core/fetchers/authorPage';

import withPageHocs from 'core/components/withPageHocs';
import withBreakpoint from 'core/components/breakpoint/withBreakpoint';

import AuthorPage from 'core/components/AuthorPage';
import { Block, Section } from 'core/components/Grid';

import LightPageWrapper from 'site/components/LightPageWrapper';
import InnerSideColumn from 'site/components/InnerSideColumn';
import Boroda from 'site/components/Boroda';

import Card1 from 'site/cards/Card1';
import Card2 from 'site/cards/Card2';


const LIMIT = 15;

function Author({ author, topics, isMobile }) {
  return (
    <>
      <LightPageWrapper>
        <Section>
          <Block>
            <AuthorPage
              author={author}
              topics={topics}
              card={isMobile ? Card1 : Card2}
              limit={LIMIT}
              columns={3}
              grid
            />
          </Block>
          <InnerSideColumn />
        </Section>
      </LightPageWrapper>
      <Boroda />
    </>
  );
}

Author.propTypes = {
  topics: PropTypes.object,
  author: PropTypes.object,
  isMobile: PropTypes.bool,
};

const dataProvider = resolve({
  author: authorFetcher(),
  topics: topicsFetcher({
    limit: LIMIT,
    include: 'image,rubric',
    fields: filterRequiredParams([Card1, Card2], 'fields'),
  }),
});

export default withPageHocs(dataProvider)(withBreakpoint(Author));
